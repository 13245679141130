import Vue from 'vue'
import 'vant/lib/index.css'

import { Button } from 'vant'
import { Icon } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Toast } from 'vant';
import { Popup } from 'vant';

Vue.use(Popup);
Vue.use(Toast);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Icon);
Vue.use(Button)
