<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "app",
  components: {},
  mounted() {
  },
  // 2022-12-01 解决vuex页面刷新数据丢失问题
  created() {
    // 判断进入终端类型
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );

    if (flag) {
      // 移动端标识
      Vue.prototype.Terminal_type = "mobile";
      document.body.className = "mobile";
    } else {
      // PC标识
      Vue.prototype.Terminal_type = "pc";
      document.body.className = "pc";
    }

    // 如果sesstion中存在Token 证明刷新过
    if (sessionStorage.getItem("Token")) {
      // 将sesstion中的Token存储到vuex中
      this.$store.commit(
        "add_Token",
        JSON.parse(sessionStorage.getItem("Token"))
      );
      // 并且删除临时会话中的Token
      sessionStorage.removeItem("Token");
    }
    // 监听页面刷新时将登录token临时存储到sesstion中
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("Token", JSON.stringify(this.$store.state.Token));
    });
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
input {
  border: none;
}
table {
  border-collapse: collapse;
}
a,
a:hover {
  text-decoration: none; /* 去除默认的下划线 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
  text-decoration: none;
}
</style>
