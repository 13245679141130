import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Message from 'element-ui'
import MessageBox from 'element-ui'
import Loading from 'element-ui'
import Notification from 'element-ui'
import Switch from 'element-ui'
Vue.use(Notification)
Vue.use(Element)
Vue.use(Switch)

Vue.use(MessageBox)
Vue.use(Message)
Vue.use(Loading)