import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // top_click_type : 0
    Token : {}
  },
  mutations: {
    add_Token(state,token_obj){
      console.log(state,token_obj)
      state.Token = token_obj
    }
    // updata_Top_click_type(state,type_number){
    //   state.top_click_type = type_number.top_click_type
    // }
  },
  actions: {

  },
  modules: {

  }
})
