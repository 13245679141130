import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/WeChatApplet',
    name: 'WeChatApplet',
    component: () => import('../MainDirectory/WeChatApplet.vue')
  },
  {
    path: '/SeatSelection',
    name: 'SeatSelection',
    component: () => import('../MainDirectory/SeatSelection.vue')
  },
  {
    path: '/SouthernOrder',
    name: 'SouthernOrder',
    component: () => import('../MainDirectory/SouthernOrder.vue')
  },
  {
    path: '/Eastern_Allowance_Change',
    name: 'Eastern_Allowance_Change',
    component: () => import('../MainDirectory/Eastern_Allowance_Change.vue')
  },
  {
    path: '/Eastern_Seat_Selection',
    name: 'Eastern_Seat_Selection',
    component: () => import('../MainDirectory/Eastern_Seat_Selection.vue')
  },
  {
    path: '/Eastern_Seat_Order',
    name: 'Eastern_Seat_Order',
    component: () => import('../MainDirectory/Eastern_Seat_Order.vue')
  },
  {
    path: '/Baggage_Change_Order',
    name: 'Baggage_Change_Order',
    component: () => import('../MainDirectory/Baggage_Change_Order.vue')
  },
  {
    path: '/Eastern_Account_Number',
    name: 'Eastern_Account_Number',
    component: () => import('../MainDirectory/Eastern_Account_Number.vue')
  },
  {
    path: '/Eastern_Account_Daily_Record',
    name: 'Eastern_Account_Daily_Record',
    component: () => import('../MainDirectory/Eastern_Account_Daily_Record.vue')
  },
  {
    path: '/MU_WeChatApplet',
    name: 'MU_WeChatApplet',
    component: () => import('../MainDirectory/MU_WeChatApplet.vue')
  },
  // 
]
// 解决路由跳转重定向重复的问题 22-11-09
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
