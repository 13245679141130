var prefix = 'https://wx.yaolvxing.com/'
let promise
import axios from 'axios';
import router from '../router';
// axios取消请求方法
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
// 登录页面方法
const toLogin = () => {
    vm.$router.replace({
        path: '/',
    })
}
let error_sesstion = '%E8%AF%B7%E7%99%BB%E5%BD%95%E5%90%8E%E6%93%8D%E4%BD%9C%0A'
// 加载框 首次在请求拦截中赋值
var laod = '';
// 最大请求时间
axios.defaults.timeout = 60000;
// POST默认请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 统一请求状态码
const errorHandle = (status, other) => {
    switch (status) {
        case 401:
            toLogin();
            break;
    }
}
axios.interceptors.request.use(config => {
    // 拒绝跳转到Login中登录
    const Path_error = ['/']
    if (!vm.$store.state.Token?.token) {
        if (!Path_error.includes(router.currentRoute.fullPath)) {
            //不是规定的页面可以进行跳转中断
            config['cancelToken'] = source.token
            source.cancel(decodeURIComponent(error_sesstion));
            toLogin()
            return config;
        }
    }
    laod = vm.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    return config;
}, error => {
    laod.close()
    return Promise.error(error);
})

axios.interceptors.response.use(response => {
    laod.close()
    if (response.status === 200) {
        return Promise.resolve(response);
    } else {
        // 除开200以外的2xx状态码
        return Promise.reject(response);
    }
}, error => {
    if (error?.message == '请登录后操作') {
        return Promise.reject(error);
    }
    const { response } = error;
    if (response) {
        // 请求已发出，但是不在2xx的范围 
        errorHandle(response.status, response.data.message);
        return Promise.reject(response);
    } else {
        if (!window.navigator.onLine) {
            // 处理断网的情况
            vm.$message.error("请检查网络连接");
            return Promise.reject(error);
        } else {
            // 请求超过最大连接时间
            vm.$message.error("超出最大连接时间");
            return Promise.reject(error);
        }
    }
})

// 请求封装导出
export default function http(url, data, method) {
    const mode_time =  setTimeout(() => {
        this.$message.closeAll()
        clearTimeout(mode_time)
    }, 1000);
    let https = prefix + url
    return new Promise((resolve, reject) => {
        //GET
        if (method == 'get') {
            promise = axios({
                url: https,
                data: data,
                method: 'GET',
                header: {

                }
            }).then(res => {
                if (res.data.rsCode != '000000') {
                    this.$message.error(res.data.rsMessage);
                } else {
                    // this.$message.success.duration = 100
                    this.$message.success(res.data.rsMessage)
                }
                resolve(res)
            }).catch(err => {
                this.$message.error(err);
                reject(err);
            })
        }
        //POST
        if (method == 'post') {
            promise = axios({
                url: https,
                data: data,
                method: 'POST',
                header: {
                    // token
                    // header,
                }
            }).then(res => {
                // || 后面是是登录状态
                if (res.data.rsCode != '000000' && res.data.code != 1) {
                    this.$message.error(res.data.rsMessage || res.data.msg);
                } else {
                    this.$message.success(res.data.rsMessage || res.data.msg);
                }
                resolve(res)
            }).catch(err => {
                this.$message.error(err);
                reject(err);
            })
        }
    })
}


