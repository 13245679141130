import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant.js'
import './plugins/element.js'
import https from './encapsulationAPI/encapsulation_Axios'
Vue.prototype.$https = https
Vue.config.productionTip = false
// vue 数据导出
import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)
// 统一消息提示持续时间



// 判断进入终端类型
let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
if (flag) {
  // 移动端
  require('./moblie_public.less');
  // 移动端标识
  Vue.prototype.Terminal_type = 'mobile'
} else {
  // PC端
  require('./pc_public.less');
  // PC标识
  Vue.prototype.Terminal_type = 'pc'
}
window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
